export default function Custom404() {
  return (
    <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center',  height: 'inherit', alignItems: 'center', color: '#000'}}>
      <section style={{textAlign: 'center'}}>
        <h2>Siden ikke funnet...<br/>Beklager, det ser ut som du har kommet til en bomvei.</h2><br/>
        <img src="https://as1.ftcdn.net/v2/jpg/05/21/22/34/1000_F_521223435_3i6bSjxVZ5oVA1lMqBS2tGlpMd80ZL3t.jpg" 
        alt="404 Not Found" style={{height: '350px', borderRadius: '32px'}}/ >
        <div style={{fontWeight: 700, fontSize: '18px', marginTop: '32px'}}>
          Men bruk søkemotoren for å finne det du leter etter!
        </div>
      </section>
    </div>
  );
}